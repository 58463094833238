<template>
  <div v-if="this.$route.name == 'Admin_Users'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Users', route: 'Admin_Users' },
      ]"
    />
    <div class="placeholder">
      <Admin_Users_ListDatatable />
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Admin_Users_ListDatatable from "@/components/Admin.Users.ListDatatable.vue";
import Navigation from "@/components/Navigation.vue";
export default {
  name: "Admin_Users",
  setup() {
    const store = useStore();

    return { store };
  },
  components: {
    Navigation,
    Admin_Users_ListDatatable,
  },
  computed: {
    FullName() {
      return this.store.state["User"].info.name;
    },
    isStaff() {
      return this.store.state["User"].info.is_staff;
    },
    avatar() {
      return this.store.state["User"].info.avatar;
    },
  },
};
</script>

<style>
</style>